import React, { useState, useContext, useEffect, useRef } from "react";
import CallSubForm from "./reusable/call-subform";
import VisitSubForm from "./reusable/visit-subform";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import loader2 from "../../images/loader_2.gif";
import { getCurrentDateToday, convertTo12HourPlusDateFormat } from "../../util/helperFunctions";
import "../../styles/edsr.css";

function EprForm({ formDetails, setFormSubmitted, setReferenceId, setDateAndTime }) {
  const { id, purchase_requisition_company_detail = [], purchase_requisition_pr_detail = [] } = formDetails;

  const [visits, setVisits] = useState([{ id: 1 }]); // Visits
  const [calls, setCalls] = useState([{ id: 1 }]); // Calls

  const [company, setCompany] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const recommendedSupplier = useRef(null);
  const urgent = useRef(null);
  const dateNeeded = useRef(null);

  const btnSubmitText = useRef(null);
  const btnLoaderImg = useRef(null);

  const selectCompanyRef = useRef(null);
  const purchaseRequestRef = useRef(null);

  const addVisits = () => {
    const newVisits = {
      id: new Date().getTime(), // unique id for each requisition
      description: "",
      remarks: "",
      quantity: 0,
      price: 0,
      cost: 0,
      attachment: [],
      file: [],
    };
    setVisits([...visits, newVisits]);
  };

  const addCalls = () => {
    const newCalls = {
      id: new Date().getTime(), // unique id for each requisition
      description: "",
      remarks: "",
      quantity: 0,
      price: 0,
      cost: 0,
      attachment: [],
      file: [],
    };
    setCalls([...calls, newCalls]);
  };

  const removeVisits = (id) => {
    setVisits(visits.filter((visit) => visit.id !== id));
  };

  const removeCalls = (id) => {
    setCalls(calls.filter((call) => call.id !== id));
  };

  const updateVisits = (updatedVisit) => {
    setVisits((prevVisits) => prevVisits.map((visit) => (visit.id === updatedVisit.id ? updatedVisit : visit)));
  };
  const updateCalls = (updatedCall) => {
    setCalls((prevCalls) => prevCalls.map((call) => (call.id === updatedCall.id ? updatedCall : call)));
  };

  const handleTotalCostChange = () => {
    const estimatedCostElements = document.querySelectorAll(".estimated-cost");
    if (estimatedCostElements.length > 0) {
      let totalCost = 0;
      estimatedCostElements.forEach((e) => {
        const target = e;
        totalCost += parseFloat(target.getAttribute("data-value"));
      });
      setTotalCost(totalCost);
    }
  };

  const handleFormValidation = () => {
    let valid = true;
    const selectCompanyValue = selectCompanyRef.current.value;
    const purchaseRequestValue = purchaseRequestRef.current.value;

    const descriptionElemments = document.querySelectorAll(".Description");
    const quantityElements = document.querySelectorAll(".Quantity");

    if (selectCompanyValue === "DEFAULT") {
      selectCompanyRef.current.classList.add("invalid-input-data");
      valid = false;
    }
    if (purchaseRequestValue === "DEFAULT") {
      purchaseRequestRef.current.classList.add("invalid-input-data");
      valid = false;
    }

    if (descriptionElemments.length > 0 && quantityElements.length > 0) {
      descriptionElemments.forEach((el) => {
        const elementValue = el.value;
        if (elementValue.trim() === "") {
          el.classList.add("invalid-input-data");
          valid = false;
        }
      });
      quantityElements.forEach((el) => {
        const elementValue = el.value;
        if (elementValue.trim() === "" || elementValue == 0) {
          el.classList.add("invalid-input-data");
          valid = false;
        }
      });
    }
    if (!valid) showSwal(common.errors.UNFILLED_INPUTS_DATA_ERROR);
    return valid;
  };

  const handleSubmitRequest = async () => {
    // const validForm = handleFormValidation();
    // if (!validForm) return;
    // if (isSubmitting) return;
    // setSubmitting(true);
    // btnSubmitText.current.style.display = "none";
    // btnLoaderImg.current.style.display = "inline-block";
    // const data = {
    //   company_id: company,
    //   recommended_supplier: recommendedSupplier.current.value,
    //   purchase_request_id: purchaseType,
    //   urgent: urgent.current.checked,
    //   date_needed: dateNeeded.current.value,
    //   visits,
    // };
    // const formData = new FormData();
    // formData.append("company_id", data.company_id);
    // formData.append("recommended_supplier", data.recommended_supplier);
    // formData.append("purchase_request_id", data.purchase_request_id);
    // formData.append("urgent", data.urgent);
    // formData.append("date_needed", data.date_needed);
    // data.visits.forEach((req, index) => {
    //   formData.append(`visits[${index}][id]`, req.id);
    //   formData.append(`visits[${index}][description]`, req.description);
    //   formData.append(`visits[${index}][remarks]`, req.remarks);
    //   formData.append(`visits[${index}][quantity]`, req.quantity);
    //   formData.append(`visits[${index}][price]`, req.price);
    //   formData.append(`visits[${index}][cost]`, req.cost);
    //   req.attachment.forEach((attachment, attIndex) => {
    //     formData.append(`visits[${index}][attachment][${attIndex}][filePreview]`, attachment.filePreview);
    //     formData.append(`visits[${index}][attachment][${attIndex}][file]`, attachment.file);
    //     formData.append(`visits[${index}][attachment][${attIndex}][type]`, attachment.type);
    //     formData.append(`visits[${index}][attachment][${attIndex}][size]`, attachment.size);
    //   });
    //   req.file.forEach((file, fileIndex) => {
    //     formData.append(`visits[${index}][file][${fileIndex}][filePreview]`, file.filePreview);
    //     formData.append(`visits[${index}][file][${fileIndex}][file]`, file.file);
    //     formData.append(`visits[${index}][file][${fileIndex}][type]`, file.type);
    //     formData.append(`visits[${index}][file][${fileIndex}][size]`, file.size);
    //   });
    // });
    // const response = await ApiService.forms.submitFormRequest(id, formData);
    // const { success, requisition } = response;
    // if (!success) return showSwal(common.errors.SUBMIT_EPR_FORM_ERROR);
    // setSubmitting(false);
    // btnSubmitText.current.style.display = "block";
    // btnLoaderImg.current.style.display = "none";
    // setReferenceId(requisition.reference_id);
    // setDateAndTime(convertTo12HourPlusDateFormat(requisition.createdAt));
    // setFormSubmitted(true);
  };

  return (
    <div className='epr-form'>
      <div className='title'>
        <h3>Electronic Daily Sales Report</h3>
      </div>
      <div className='epr-form-fields'>
        <div className='epr-form-row'>
          <div className='purchase-requisition-dynamic'>
            <label>Visit Subform</label>
            <div className='purchase-requisition-fields'>
              {visits.map((requisition) => (
                <VisitSubForm
                  key={requisition.id}
                  id={requisition.id}
                  onRemove={removeVisits}
                  onAdd={addVisits}
                  handleTotalCostChange={handleTotalCostChange}
                  updateVisits={updateVisits}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='epr-form-row'>
          <div className='purchase-requisition-dynamic'>
            <label>Call Subform</label>
            <div className='purchase-requisition-fields'>
              {calls.map((call) => (
                <CallSubForm
                  key={call.id}
                  id={call.id}
                  onRemove={removeCalls}
                  onAdd={addCalls}
                  handleTotalCostChange={handleTotalCostChange}
                  updateVisits={updateCalls}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='epr-form-row'>
          <label htmlFor='images'>Signature</label>
          <input type='file' id='images' multiple />
        </div>
      </div>
      <div className='submit-action-btn'>
        <button className='search-btn submit-request' onClick={(e) => handleSubmitRequest()}>
          <span className='form-btn-txt' ref={btnSubmitText}>
            Submit Request
          </span>
          <img src={loader2} className='form-loader' ref={btnLoaderImg} style={{ display: "none" }} />
        </button>
      </div>
    </div>
  );
}
export default EprForm;
