import React, { useEffect, useState } from "react";

function InputField({ fieldName, required, handleOnChange, value }) {
  return (
    <div className='fields'>
      <span className='field-name'>
        {fieldName} {required ? <span className='epr-required'>*</span> : ""}
      </span>
      <input
        className={`${fieldName}`}
        type={fieldName === "Quantity" || fieldName === "Estimated Price" || fieldName === "Amount" ? "number" : "text"}
        onKeyDown={(e) => {
          if (
            fieldName === "Quantity" ||
            fieldName === "Estimated Price" ||
            fieldName === "Description" ||
            fieldName === "Amount" ||
            fieldName === "Remarks" ||
            fieldName === "Received Items"
          ) {
            setTimeout(() => {
              handleOnChange(fieldName, e.target);
            }, 1000);
          }
        }}
      />
    </div>
  );
}

export default InputField;
