import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import EprForm from "./epr";
import OffsettingForm from "./offsetting-form";
import ProspectingForm from "./prospecting-form";
import TransmittalForm from "./transmittal-form";
import ExplainWhyForm from "./explain-why-form";
import CcarForm from "./ccar-form";
import SoLoForm from "./so-lo-form";
import Edsr from "./edsr";
import ApiService from "../../api/apiService";
import { common } from "../../util/common";
import showSwal from "../swal";

function RequestNew() {
  const formId = useLocation();
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [formDetails, setFormDetails] = useState(null);

  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [referenceId, setReferenceId] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(formId.search);
    const form = searchParams.get("f");
    if (form !== "" && form !== null) {
      setSelectedFormId(form);
    }
  }, [formId.search]);

  useEffect(() => {
    const getFormDetails = async () => {
      if (selectedFormId) {
        const formDetail = await ApiService.forms.getFormDetailById(selectedFormId);
        const { success, permissions, form_details } = formDetail;

        if (!success) return showSwal(common.errors.FETCH_FORM_DETAIL_ERROR);

        const { add_page, view_page, open_page } = permissions;

        if (!add_page || !view_page || !open_page) return showSwal(common.errors.PERMISSION_ERROR);

        setFormDetails(form_details);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };

    getFormDetails();
  }, [selectedFormId]);

  const submittedForm = () => {
    return (
      <div className='success-form'>
        <div className='success-header'>
          <i className='fa fa-check-square-o fa-5x' aria-hidden='true'></i>
          <h1>Your request has been submitted.</h1>
        </div>
        <div className='success-body'>
          <h3>Kindly take note of the details of your request for reference.</h3>
          <div>
            <span>Reference ID: </span>
            <span>&nbsp;{referenceId}</span>
          </div>
          <div>
            <span>Date & Time: </span>
            <span>&nbsp;{dateAndTime}</span>
          </div>
          <small>This can also be found in "My Requests" tab</small>
        </div>
      </div>
    );
  };
  return (
    <>
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : (
        <div className='request-new-form'>
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "epr" && (
            <EprForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "offsetting" && (
            <OffsettingForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "prospecting" && (
            <ProspectingForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "transmittal" && (
            <TransmittalForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "explain-why" && (
            <ExplainWhyForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "ccar" && (
            <CcarForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "so-lo" && (
            <SoLoForm
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {!isFormSubmitted && selectedFormId && formDetails && formDetails.type === "edsr" && (
            <Edsr
              formDetails={formDetails}
              setFormSubmitted={setFormSubmitted}
              setReferenceId={setReferenceId}
              setDateAndTime={setDateAndTime}
            />
          )}
          {isFormSubmitted && submittedForm()}
        </div>
      )}
    </>
  );
}

export default RequestNew;
