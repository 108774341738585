import React, { useState, useContext, useEffect, useRef } from "react";
import "../../styles/offsetting.css";
import loader2 from "../../images/loader_2.gif";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import { convertTo12HourPlusDateFormat } from "../../util/helperFunctions";
import "../../styles/prospecting-form.css";

function OffsettingForm({ formDetails, setReferenceId, setDateAndTime, setFormSubmitted }) {
  const loaderRef = useRef(null);

  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const [images, setImages] = useState(null);

  const [formData, setFormData] = useState({
    specificAddress: "",
    companyName: "",
    contactName: "",
    position: "",
    mobileNumber: null,
    telephone1: null,
    telephone2: null,
    supplier: null,
    volume: null,
    prospectsEmail: null,
  });

  const handleImageChange = (event) => {
    const files = event.target.files;
    setImages(files);
  };

  const handleDataChange = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedProvince("");
  };

  const getProvinces = (regionName) => {
    const region = philippineRegions.find((r) => r.name === regionName);
    return region ? region.provinces : [];
  };
  const philippineRegions = [
    {
      name: "NCR - National Capital Region",
      provinces: [
        "Caloocan",
        "Las Piñas",
        "Makati",
        "Malabon",
        "Mandaluyong",
        "Manila",
        "Marikina",
        "Muntinlupa",
        "Navotas",
        "Parañaque",
        "Pasay",
        "Pasig",
        "Quezon City",
        "San Juan",
        "Taguig",
        "Valenzuela",
        "Pateros",
      ],
    },
    {
      name: "CAR - Cordillera Administrative Region",
      provinces: ["Abra", "Apayao", "Benguet", "Ifugao", "Kalinga", "Mountain Province"],
    },
    {
      name: "BARMM - Bangsamoro Autonomous Region in Muslim Mindanao",
      provinces: ["Basilan", "Lanao del Sur", "Maguindanao del Norte", "Maguindanao del Sur", "Sulu", "Tawi-Tawi"],
    },
    {
      name: "Region I - Ilocos Region",
      provinces: ["Ilocos Norte", "Ilocos Sur", "La Union", "Pangasinan"],
    },
    {
      name: "Region II - Cagayan Valley",
      provinces: ["Batanes", "Cagayan", "Isabela", "Nueva Vizcaya", "Quirino"],
    },
    {
      name: "Region III - Central Luzon",
      provinces: ["Aurora", "Bataan", "Bulacan", "Nueva Ecija", "Pampanga", "Tarlac", "Zambales"],
    },
    {
      name: "Region IV-A - CALABARZON",
      provinces: ["Batangas", "Cavite", "Laguna", "Quezon", "Rizal"],
    },
    {
      name: "Region IV-B - MIMAROPA",
      provinces: ["Marinduque", "Occidental Mindoro", "Oriental Mindoro", "Palawan", "Romblon"],
    },
    {
      name: "Region V - Bicol Region",
      provinces: ["Albay", "Camarines Norte", "Camarines Sur", "Catanduanes", "Masbate", "Sorsogon"],
    },
    {
      name: "Region VI - Western Visayas",
      provinces: ["Aklan", "Antique", "Capiz", "Guimaras", "Iloilo", "Negros Occidental"],
    },
    {
      name: "Region VII - Central Visayas",
      provinces: ["Bohol", "Cebu", "Negros Oriental", "Siquijor"],
    },
    {
      name: "Region VIII - Eastern Visayas",
      provinces: ["Biliran", "Eastern Samar", "Leyte", "Northern Samar", "Samar", "Southern Leyte"],
    },
    {
      name: "Region IX - Zamboanga Peninsula",
      provinces: ["Zamboanga del Norte", "Zamboanga del Sur", "Zamboanga Sibugay"],
    },
    {
      name: "Region X - Northern Mindanao",
      provinces: ["Bukidnon", "Camiguin", "Lanao del Norte", "Misamis Occidental", "Misamis Oriental"],
    },
    {
      name: "Region XI - Davao Region",
      provinces: ["Davao de Oro", "Davao del Norte", "Davao del Sur", "Davao Occidental", "Davao Oriental"],
    },
    {
      name: "Region XII - SOCCSKSARGEN",
      provinces: ["Cotabato", "Sarangani", "South Cotabato", "Sultan Kudarat"],
    },
    {
      name: "Region XIII - Caraga",
      provinces: ["Agusan del Norte", "Agusan del Sur", "Dinagat Islands", "Surigao del Norte", "Surigao del Sur"],
    },
  ];

  const handleSubmit = async (e) => {
    const {
      specificAddress,
      companyName,
      position,
      mobileNumber,
      telephone1,
      telephone2,
      supplier,
      volume,
      prospectsEmail,
    } = formData;

    const _formData = new FormData();
    _formData.append("specificAddress", specificAddress);
    _formData.append("companyName", companyName);
    _formData.append("position", position);
    _formData.append("mobileNumber", mobileNumber);
    _formData.append("telephone1", telephone1);
    _formData.append("telephone2", telephone2);
    _formData.append("supplier", supplier);
    _formData.append("volume", volume);
    _formData.append("prospectsEmail", prospectsEmail);

    if (images) {
      for (let i = 0; i < images.length; i++) {
        _formData.append("prospectingImages", images[i]);
      }
    }

    e.target.style.display = "none";
    loaderRef.current.style.display = "inline-block";

    const response = await ApiService.forms.submitProspectingRequest(formDetails.id, _formData);
    const { success, prospecting } = response;
    if (!success) return showSwal(common.errors.SUBMIT_OFFSET_FORM_ERROR);
    setSubmitting(false);
    e.target.style.display = "block";
    loaderRef.current.style.display = "none";
    setReferenceId(prospecting.reference_id);
    setDateAndTime(convertTo12HourPlusDateFormat(prospecting.createdAt));
    setFormSubmitted(true);
  };

  return (
    <div className='epr-form-container'>
      <div className='epr-title'>
        <h3>Prospecting Form</h3>
      </div>
      <div className='epr-form-body'>
        <div className='epr-form-groupings'>
          <div className='epr-form-group'>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor='region'>
                Region<span className='epr-required'>*</span>
              </label>
              <select name='region' id='region' value={selectedRegion} onChange={handleRegionChange}>
                <option value=''>Select a Region</option>
                {philippineRegions.map((region, index) => (
                  <option value={region.name} key={`${index}-${region.name}`}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>

            <div className='epr-form-fields-prospecting'>
              <label htmlFor='province'>
                Province<span className='epr-required'>*</span>
              </label>
              <select
                name='province'
                id='province'
                value={selectedProvince}
                onChange={(e) => setSelectedProvince(e.target.value)}
                disabled={!selectedRegion}
              >
                <option value=''>Select a Province</option>
                {selectedRegion &&
                  getProvinces(selectedRegion).map((province, index) => (
                    <option value={province} key={`${index}-${province}`}>
                      {province}
                    </option>
                  ))}
              </select>
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>
                Specific Address<span className='epr-required'>*</span>
              </label>
              <input type='text' onChange={(e) => handleDataChange("specificAddress", e.target.value)} />
            </div>
          </div>
          <div className='epr-form-group'>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>
                Company Name<span className='epr-required'>*</span>
              </label>
              <input type='text' onChange={(e) => handleDataChange("companyName", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>
                Contact Name<span className='epr-required'>*</span>
              </label>
              <input type='text' onChange={(e) => handleDataChange("contactName", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>
                Position<span className='epr-required'>*</span>
              </label>
              <input type='text' onChange={(e) => handleDataChange("position", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Mobile number</label>
              <input type='text' onChange={(e) => handleDataChange("mobileNumber", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Telephone No. 1</label>
              <input type='text' onChange={(e) => handleDataChange("telephone1", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Telephone No. 2</label>
              <input type='text' onChange={(e) => handleDataChange("telephone2", e.target.value)} />
            </div>
          </div>
          <div className='epr-form-group'>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Supplier</label>
              <input type='text' onChange={(e) => handleDataChange("supplier", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Volume</label>
              <input type='text' onChange={(e) => handleDataChange("volume", e.target.value)} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Prospect's Email</label>
              <input type='text' onChange={(e) => handleDataChange("prospectsEmail", e.target.value)} />
            </div>

            <div className='epr-form-fields-prospecting'>
              <label htmlFor='images'>Image Upload</label>
              <input type='file' id='images' multiple onChange={handleImageChange} />
            </div>
          </div>
        </div>
        <div className='epr-submit-action-btn'>
          <button className='epr-submit-request' onClick={(e) => handleSubmit(e)}>
            <span className='epr-form-btn-txt'>Submit Request</span>
            <img src={loader2} className='epr-form-loader' style={{ display: "none" }} ref={loaderRef} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default OffsettingForm;
