import React, { useState, useContext, useEffect, useRef } from "react";
import "../../styles/offsetting.css";
import loader2 from "../../images/loader_2.gif";
import Offsetting from "./reusable/offsetting";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import { convertTo12HourPlusDateFormat } from "../../util/helperFunctions";

function OffsettingForm({ formDetails, setReferenceId, setDateAndTime, setFormSubmitted }) {
  // Form Data
  const [totalHours, setTotalHours] = useState(0);
  const [dateOffset, setDateOffset] = useState(new Date());
  const [typeOffset, setTypeOffset] = useState("Whole Day");
  // Form Data

  const loaderRef = useRef(null);
  const [offSetting, setOffSetting] = useState([
    {
      id: new Date().getTime(),
      date: new Date(),
      accumulatedHrs: 2,
    },
  ]);

  const [isSubmitting, setSubmitting] = useState(false);

  const addOffsetting = () => {
    if (offSetting.length > 0) {
      const newOffsetting = {
        id: new Date().getTime(),
        date: new Date(),
        accumulatedHrs: 2,
      };
      setOffSetting([...offSetting, newOffsetting]);
    }
  };

  const removeOffsetting = (id) => {
    if (!offSetting.length < 1) {
      setOffSetting(offSetting.filter((offset) => offset.id !== id));
    }
  };

  const setAccumulatedHours = (id, value) => {
    if (offSetting.length > 0) {
      const offSettingRows = [...offSetting];
      const offsettingRow = offSettingRows.find((offset) => offset.id === id);
      if (offsettingRow) {
        offsettingRow.accumulatedHrs = value;
        setOffSetting(offSettingRows);
      }
    }
  };

  useEffect(() => {
    const totalHoursOffset = offSetting.reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue.accumulatedHrs),
      0
    );
    setTotalHours(totalHoursOffset);
  }, [offSetting]);

  const handleSubmit = async (e) => {
    const data = {
      offsettingRows: offSetting,
      totalHours: totalHours,
      dateOffset: dateOffset,
      typeOffset: typeOffset,
    };

    const apiResponse = await ApiService.forms.submitOffsetRequest(formDetails.id, data);
    e.target.style.display = "none";
    loaderRef.current.style.display = "inline-block";
    const { success, offset } = apiResponse;
    if (!success) return showSwal(common.errors.SUBMIT_OFFSET_FORM_ERROR);
    setSubmitting(false);
    e.target.style.display = "block";
    loaderRef.current.style.display = "none";

    setReferenceId(offset.reference_id);
    setDateAndTime(convertTo12HourPlusDateFormat(offset.createdAt));
    setFormSubmitted(true);
  };

  return (
    <div className='epr-form'>
      <div className='title'>
        <h3>Offsetting Form</h3>
      </div>
      <div className='form-body'>
        <div className='offsetting-container'>
          {offSetting.map((_, index) => (
            <Offsetting
              key={index}
              addOffsetting={addOffsetting}
              removeOffsetting={removeOffsetting}
              offset={_}
              setAccumulatedHours={setAccumulatedHours}
            />
          ))}
        </div>
        <div className='form-fields'>
          <label htmlFor=''>Total of Extended Hours Accumulated</label>
          <input type='text' value={totalHours} disabled />
        </div>
        <div className='form-fields'>
          <label htmlFor=''>
            Date of Offset <span className='required'>*</span>
          </label>
          <input type='date' value={dateOffset} onChange={(e) => setDateOffset(e.target.value)} />
        </div>
        <div className='form-fields'>
          <label htmlFor=''>
            Type of Offset <span className='required'>*</span>
          </label>
          <select name='' id='' value={typeOffset} onChange={(e) => setTypeOffset(e.target.value)}>
            <option value='Whole Day'>Whole Day</option>
            <option value='1st Half'>1st Half</option>
            <option value='2nd Half'>2nd Half</option>
          </select>
        </div>
        <div className='submit-action-btn'>
          <button className='search-btn submit-request'>
            <span className='form-btn-txt' onClick={(e) => handleSubmit(e)}>
              Submit Request
            </span>
            <img src={loader2} className='form-loader' style={{ display: "none" }} ref={loaderRef} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default OffsettingForm;
