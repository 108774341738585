import React, { useState, useEffect, useContext } from "react";
import "./toggle-switch.css";
import ApiService from "../../api/apiService";
import { ChatContext } from "../../context/contexts";

const ToggleSwitch = () => {
  const Chat = useContext(ChatContext);

  const [activeToggle, setActiveToggle] = useState("personal");

  useEffect(() => {
    const conversationFilter = localStorage.getItem("conversation_filter");
    if (conversationFilter) {
      setActiveToggle(conversationFilter);
      Chat.setConversationFlag(conversationFilter);
    }
  }, []);
  const [personalCount, setPersonalCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const handleToggle = (toggle) => {
    localStorage.setItem("conversation_filter", toggle);
    Chat.setConversationFlag(toggle);
    Chat.setConversationLimit(10);
    setActiveToggle(toggle);
  };

  useEffect(() => {
    const getConversationUnreadCount = async () => {
      const unreadCounts = await ApiService.v2.conversations.getConversationUnreadCount();

      const { success, count } = unreadCounts;
      if (success) {
        const { group, personal } = count;
        setPersonalCount(personal);
        setGroupCount(group);
      }
    };
    getConversationUnreadCount();
    const intervalId = setInterval(getConversationUnreadCount, 2500);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='toggle-container'>
      <div
        className={`toggle-button ${activeToggle === "personal" ? "active" : ""}`}
        onClick={() => handleToggle("personal")}
      >
        Personal
        {personalCount > 0 && <span className='badge'>{personalCount}</span>}
      </div>
      <div
        className={`toggle-button ${activeToggle === "group" ? "active" : ""}`}
        onClick={() => handleToggle("group")}
      >
        Group
        {groupCount > 0 && <span className='badge'>{groupCount}</span>}
      </div>
    </div>
  );
};

export default ToggleSwitch;
