import React, { useState, useContext, useRef, useEffect } from "react";
import emptyImage from "../images/empty.jpg";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import autosize from "autosize";
import loader2 from "../images/loader_2.gif";
import { ChatContext, WebSocketContext } from "../context/contexts";
import { convertTo12HourFormat, getTimeAgo, formatBoldText } from "../util/helperFunctions";
import MessageComponent from "./message";
import ReactionsIndicator from "./reusable/reactions-indicator";
import { groupReactions } from "../util/helperFunctions";
import { resizeFile } from "../hooks/resizeImage";

function ViewThreadModal({ userId, handleImageClick }) {
  const Chat = useContext(ChatContext);
  const WS = useContext(WebSocketContext);
  const textArea = document.querySelector(".view-thread-reply-textarea");
  const fileInputRef = useRef(null);
  autosize(textArea);

  const { user, content, createdAt, id, is_deleted, message_attachment, thread_replies, message_reactions } =
    Chat.threadViewDetails;

  const [selectedFile, setSelectedFile] = useState([]);
  const [filePreview, setFilePreview] = useState([]);

  const [messageContent, setMessageContent] = useState("");
  const [isSendingMsg, setIsSendingMsg] = useState(false);

  const [error, setError] = useState(null);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedMentionData, setSelectedMentionData] = useState([]);
  const [mentionText, setMentionText] = useState("");
  const [conversationMemberSuggestions, setConversationMemberSuggestions] = useState([]);

  const [onlineUserData, setOnlineUserData] = useState(null);

  useEffect(() => {
    const onlineUsers = Chat.onlineUsers;

    const onlineData = onlineUsers.find((onlineUser) => onlineUser.user_id === user.id);
    setOnlineUserData(onlineData);
  }, [Chat.onlineUsers]);

  const allItems = thread_replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  useEffect(() => {
    if (mentionText) {
      const filteredSuggestions = Chat.conversationMembersData.filter((member) =>
        member.user.display_name.toLowerCase().startsWith(mentionText.toLowerCase())
      );
      setConversationMemberSuggestions(filteredSuggestions);
    }
  }, [mentionText]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const maxFileSizeMB = process.env.REACT_APP_MAX_ATTACHMENT_SIZE_IN_MB;
    const newFilePreviews = [];
    const newSelectedFiles = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const fileSizeMB = files[i].size / 1024 / 1024;
        if (fileSizeMB > maxFileSizeMB) {
          showSwal({
            title: "Something went wrong",
            icon: "error",
            text: `File ${files[i].name} exceeds 15MB and will not be uploaded.`,
            confirmButtonText: "OK",
          });
          continue;
        }
        const objProperty = {
          filePreview: URL.createObjectURL(files[i]),
          file: files[i],
          type: files[i].type,
        };
        newSelectedFiles.push(objProperty);
        newFilePreviews.push(objProperty);
      }
      setSelectedFile(newSelectedFiles);
      setFilePreview(newFilePreviews);
    }
  };

  const handleRemoveFile = (filePreviewUrl) => {
    setFilePreview(filePreview.filter((file) => file.filePreview !== filePreviewUrl));
    setSelectedFile(selectedFile.filter((file) => file.filePreview !== filePreviewUrl));
  };

  const handleSuggestionClick = (suggestionObj) => {
    const updatedMessage = messageContent.replace(/@\w*$/, `@${suggestionObj.name}@@ `);
    setMessageContent(updatedMessage);
    setSelectedMentionData((prevState) => [...prevState, suggestionObj]);
    setShowSuggestions(false);
  };

  const renderAttachment = (attachment, file_type, thread_attachment, index) => {
    const FINAL_RESOURCES_URL = process.env.REACT_APP_CHAT_RESOURCES_URL;

    const attachmentLink = `${FINAL_RESOURCES_URL}${attachment}`;

    const img = (
      <img
        src={attachmentLink}
        alt='image-attachment'
        onClick={(e) => handleImageClick([{ src: attachmentLink, type: "image" }])}
        className='thread-image-attachment'
        key={index}
      />
    );

    const video = (
      <video
        controls
        src={attachmentLink}
        className='thread-image-attachment'
        // onClick={() => handleImageClick([{ type: "video", sources: [{ src: attachmentLink, type: "video/mp4" }] }])}
        style={{ cursor: "pointer" }}
        key={index}
      >
        Your browser does not support the video tag.
      </video>
    );
    const pdf = (
      <a href={attachmentLink} target='_blank' rel='noopener noreferrer' key={index}>
        <div className='attachment-column'>
          <i className='fa fa-file-o fa-5x' aria-hidden='true'></i>
          <small>{attachment}</small>
        </div>
      </a>
    );

    const _pdf = (
      <a
        href={attachmentLink}
        className='message__item-attachment-file view-thread-reply-attachment'
        target='_blank'
        rel='noopener noreferrer'
        key={index}
      >
        Click to view the file
      </a>
    );

    if (file_type === "application/pdf") {
      if (thread_attachment) {
        return _pdf;
      } else {
        return pdf;
      }
    } else if (file_type.includes("video")) {
      return video;
    } else if (file_type.includes("image")) {
      return img;
    } else {
      if (thread_attachment) {
        return _pdf;
      } else {
        return pdf;
      }
    }
  };

  const buildProfileImageSrc = (userProfile) => {
    const profileImage =
      userProfile === "" || userProfile === null
        ? emptyImage
        : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${userProfile}`;

    return profileImage;
  };

  const renderUserChatAttachment = (attachment, index) => {
    const img = (
      <div className='attachment' key={index}>
        <span onClick={() => handleRemoveFile(attachment.filePreview)}>X</span>
        <img
          src={attachment.filePreview}
          alt=''
          onClick={(e) => handleImageClick([{ src: attachment.filePreview, type: "image" }])}
          className='thread-image-attachment'
        />
      </div>
    );

    const video = (
      <div className='attachment' key={index}>
        <span onClick={() => handleRemoveFile(attachment.filePreview)}>X</span>
        <video
          src={attachment.filePreview}
          alt=''
          className='thread-image-attachment'
          style={{ cursor: "pointer" }}
        ></video>
      </div>
    );

    const pdf = (
      <div className='attachment' key={index}>
        <span onClick={() => handleRemoveFile(attachment.filePreview)}>X</span>
        <div className='attachment-column'>
          <i className='fa fa-file-o fa-5x' aria-hidden='true'></i>
          <small>{attachment.file.name}</small>
        </div>
      </div>
    );

    if (attachment.type.includes("image")) {
      return img;
    } else if (attachment.type.includes("vide")) {
      return video;
    } else {
      return pdf;
    }
  };

  const handleMessageInput = (e) => {
    const input = e.target.value;
    setMessageContent(input);

    const mentionMatch = input.match(/@@(\w*)$/);
    if (mentionMatch) {
      setMentionText(mentionMatch[1]);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

    updateMentions(input);
  };

  const updateMentions = (input) => {
    const mentionRegex = /@@([\w\s]+?)@@/g;
    let match;
    const mentions = [];

    while ((match = mentionRegex.exec(input)) !== null) {
      mentions.push(match[1]);
    }

    const updatedMentionData = selectedMentionData.filter((mention) => mentions.includes(mention.name));
    setSelectedMentionData(updatedMentionData);
  };

  const handleMessageInputPaste = (event) => {
    const clipboardItems = event.clipboardData.items;
    const dataTransfer = new DataTransfer();

    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      for (let i = 0; i < fileInputRef.current.files.length; i++) {
        dataTransfer.items.add(fileInputRef.current.files[i]);
      }
    }

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];

      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          dataTransfer.items.add(file);
        }
      }
    }

    if (dataTransfer.files.length > 0) {
      fileInputRef.current.files = dataTransfer.files;

      handleFileChange({
        target: {
          files: dataTransfer.files,
        },
      });
    }
  };

  const handleRemoveFiles = () => {
    setSelectedFile([]);
    setFilePreview([]);
    if (fileInputRef) fileInputRef.current.value = "";
  };

  const handleSendMessage = async () => {
    if (isSendingMsg) return;
    if (!Chat.storedConversationId) return showSwal(common.errors.INVALID_CONVERSATION);
    if (!messageContent) return showSwal(common.errors.EMPTY_MESSAGE);

    try {
      const formData = new FormData();
      if (selectedFile !== "") {
        for (const fileObj of selectedFile) {
          const resizedFile = await resizeFile(fileObj.file, 800, 800); // Set max dimensions
          formData.append("message_attachments", resizedFile, fileObj.file.name);
        }
      }
      formData.append(
        "data",
        JSON.stringify({
          message: messageContent,
          mention_data: selectedMentionData,
        })
      );
      setIsSendingMsg(true);
      document.querySelector(".message__btn-send-icon").style.display = "none";
      document.querySelector(".loader-msg").style.display = "block";

      const replyDetail = await ApiService.message.replyThreadMessage(formData, Chat.storedConversationId, id);
      const { success, message } = replyDetail;

      if (success) {
        document.querySelector(".message__btn-send-icon").style.display = "inline-block";
        document.querySelector(".loader-msg").style.display = "none";
        setIsSendingMsg(false);
        setMessageContent("");
        handleRemoveFiles();
        WS.websocketInstance.sendMessage({ message, type: "user-thread-chat" });
        Chat.reFetchThreadDetails(id);
      } else {
        showSwal(common.errors.SEND_MESSAGE_ERROR);
      }
    } catch (err) {
      showSwal(common.errors.SEND_MESSAGE_ERROR);
      setError(error);
    }
  };

  const handleDeleteMsg = async (msgId) => {
    try {
      const deleteMsg = await ApiService.message.deleteMessage(Chat.storedConversationId, msgId);

      const { success, conversation_action } = deleteMsg;
      if (!success) showSwal(common.errors.DELETE_MESSAGE_ERROR);
      WS.websocketInstance.sendMessage({ message: conversation_action, type: "user-actions-chat" });
      Chat.reFetchThreadDetails(id);
    } catch (err) {
      console.error(err);
    }
  };

  const groupedReaction = groupReactions(message_reactions);

  const userOnline = onlineUserData ? onlineUserData?.is_online : false;
  const lastLogin = userOnline ? "" : onlineUserData?.last_online ? getTimeAgo(onlineUserData?.last_online) : null;
  return (
    <div className='modal-container'>
      <div className='modal-dialog view-thread-modal-dialog'>
        <button className='close-btn' onClick={(e) => Chat.handleThreadViewVisible()}>
          ✕
        </button>
        <h3>View Thread</h3>
        <div className='modal-content view-thread-container'>
          <div className='view-thread-chat'>
            <div className='view-thread-user-information'>
              <img src={buildProfileImageSrc(user?.profile_photo)} alt='profile-photo' />
              <div className={`online-indicator-thread ${userOnline ? "online-user" : "offline-user"}`}>
                <span className='tooltip'>{`${
                  userOnline ? "Online" : `${lastLogin !== null && lastLogin !== undefined ? lastLogin : "Offline"}`
                }`}</span>
              </div>
              <div className='information-container'>
                <h4>{user?.display_name}</h4>
                <small>{getTimeAgo(createdAt)}</small>
              </div>
            </div>
            <div className='view-thread-user-chat-container'>
              <div className='view-thread-user-chat'>
                <span>
                  <MessageComponent message={content} is_deleted={is_deleted} />
                </span>
              </div>
              {is_deleted ? (
                ""
              ) : (
                <div className='view-thread-user-images'>
                  {message_attachment.map((attachment, index) =>
                    renderAttachment(attachment.attachment, attachment.file_type, false, index)
                  )}
                </div>
              )}
            </div>

            <div className='view-chat-reactions view-chat-reactions-receiver'>
              {Object.keys(groupedReaction).map((reaction, index) => (
                <ReactionsIndicator
                  reaction={`${reaction}`}
                  reaction_details={groupedReaction[reaction]}
                  key={index}
                  isThread={true}
                />
              ))}
            </div>
            <div className='view-thread-replies-container'>
              {allItems.map((thread_reply, index) => {
                const onlineUserList = Chat.onlineUsers;
                const onlineData = onlineUserList.find((onlineUser) => onlineUser.user_id === thread_reply?.user?.id);
                const userOnline = onlineData ? onlineData?.is_online : false;
                const lastLogin = userOnline ? "" : `${getTimeAgo(onlineData?.last_online)}`;

                return (
                  <div className='view-thread-reply' key={index}>
                    <div>
                      <div className='view-thread-reply-user-information'>
                        <img src={buildProfileImageSrc(thread_reply?.user?.profile_photo)} alt='profile-photo' />
                        <div className={`online-indicator-thread ${userOnline ? "online-user" : "offline-user"}`}>
                          <span className='tooltip'>{`${userOnline ? "Online" : `${lastLogin}`}`}</span>
                        </div>

                        <div className='information-container'>
                          <h4>{thread_reply?.user?.display_name}</h4>
                          {thread_reply?.is_deleted ? (
                            <span className='deleted-chat'>DELETED CHAT</span>
                          ) : (
                            <span>{formatBoldText(thread_reply?.content)}</span>
                          )}
                        </div>
                      </div>
                      {thread_reply?.is_deleted ? (
                        ""
                      ) : (
                        <div className='view-thread-reply-images'>
                          {thread_reply.message_attachment.map((attachment, index) =>
                            renderAttachment(attachment.attachment, attachment.file_type, true, index)
                          )}
                        </div>
                      )}
                      <small>{getTimeAgo(thread_reply?.createdAt)}</small>
                    </div>
                    {userId === thread_reply?.user?.id && !thread_reply?.is_deleted ? (
                      <div className='view-thread-reply-options' onClick={(e) => handleDeleteMsg(thread_reply.id)}>
                        <i className='fa fa-trash-o fa-2x' aria-hidden='true'></i>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
            <div className='view-thread-inputs'>
              <div className='view-thread-reply-attachments-container'>
                {filePreview.map((_filePreview, index) => renderUserChatAttachment(_filePreview, index))}
              </div>
              {showSuggestions && (
                <div className='mention-container'>
                  <ul className='mention-suggested-users'>
                    {showSuggestions && (
                      <>
                        {/* "Mention All" option */}
                        <li
                          className='mention-users-item'
                          key='mention-all'
                          onClick={() =>
                            handleSuggestionClick({
                              id: "all",
                              name: "All",
                            })
                          }
                        >
                          <span>
                            <strong>All</strong>
                          </span>
                        </li>

                        {/* Render other member suggestions */}
                        {conversationMemberSuggestions.map((member, index) => (
                          <li
                            className='mention-users-item'
                            key={index}
                            onClick={() =>
                              handleSuggestionClick({
                                id: member.user.id,
                                name: member.user.display_name,
                              })
                            }
                          >
                            <img src={emptyImage} className='mention-user--profile-photo' />
                            <span>{member.user.display_name}</span>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              )}
              <div className='view-thread-reply-inputs-container'>
                <textarea
                  className='message__input js-message--input msg-tool'
                  placeholder={
                    !Chat.isPermittedToChat
                      ? "You are restricted from chatting in this conversation"
                      : "Write a message..."
                  }
                  disabled={!Chat.isPermittedToChat ? true : false}
                  value={messageContent}
                  onChange={(e) => handleMessageInput(e)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey && Chat.isPermittedToChat) {
                      e.preventDefault();
                      setMessageContent((prevContent) => prevContent + "\n");
                    } else if (e.key === "Enter" && Chat.isPermittedToChat) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  onPaste={handleMessageInputPaste}
                />
                <div className='thread-reply-actions'>
                  <input
                    type='file'
                    id='thread-fileInput'
                    className='message__file-input'
                    onChange={handleFileChange}
                    multiple
                    ref={fileInputRef}
                  />
                  <button
                    className='message__btn-file js-file-upload--btn msg-tool'
                    onClick={() => document.getElementById("thread-fileInput").click()}
                  >
                    <i className='fa fa-paperclip message__btn-file-icon'></i>
                  </button>
                  <button
                    className='message__btn-send js-send-message--btn msg-tool'
                    data-receiver=''
                    data-cid=''
                    onClick={() => {
                      if (Chat.isPermittedToChat) {
                        handleSendMessage();
                      }
                    }}
                  >
                    <i className='fa fa-paper-plane message__btn-send-icon'></i>
                    <img src={loader2} className='loader-msg' />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewThreadModal;
