import React, { useState, useContext, useEffect, useRef } from "react";
import TransmittalItemDetails from "./reusable/transmittal-item-details";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import loader2 from "../../images/loader_2.gif";
import { getCurrentDateToday, convertTo12HourPlusDateFormat } from "../../util/helperFunctions";
import "../../styles/transmittal.css";

function EprForm({ formDetails, setFormSubmitted, setReferenceId, setDateAndTime }) {
  const { id, purchase_requisition_company_detail = [], purchase_requisition_pr_detail = [] } = formDetails;
  const [requisitions, setRequisitions] = useState([{ id: 1 }]);
  const [purchaseType, setPurchaseType] = useState("");
  const [purchaseTypeText, setPurchaseTypeText] = useState("");
  const [company, setCompany] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [images, setImages] = useState(null);

  const transmittalReceiver = useRef(null);
  const urgent = useRef(null);
  const dateNeeded = useRef(null);

  const btnSubmitText = useRef(null);
  const btnLoaderImg = useRef(null);

  const selectCompanyRef = useRef(null);
  const purchaseRequestRef = useRef(null);

  const addRequisition = () => {
    const newRequisition = {
      id: new Date().getTime(), // unique id for each requisition
      description: "",
      remarks: "",
      quantity: 0,
      price: 0,
    };
    setRequisitions([...requisitions, newRequisition]);
  };

  const removeRequisition = (id) => {
    setRequisitions(requisitions.filter((requisition) => requisition.id !== id));
  };

  const updateRequisition = (updatedRequisition) => {
    setRequisitions((prevRequisitions) =>
      prevRequisitions.map((requisition) =>
        requisition.id === updatedRequisition.id ? updatedRequisition : requisition
      )
    );
  };

  const handleTotalCostChange = () => {
    const estimatedCostElements = document.querySelectorAll(".estimated-cost");
    if (estimatedCostElements.length > 0) {
      let totalCost = 0;
      estimatedCostElements.forEach((e) => {
        const target = e;
        totalCost += parseFloat(target.getAttribute("data-value"));
      });
      setTotalCost(totalCost);
    }
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    setImages(files);
  };

  const handleSubmitRequest = async () => {
    if (isSubmitting) return;
    setSubmitting(true);
    btnSubmitText.current.style.display = "none";
    btnLoaderImg.current.style.display = "inline-block";

    const formData = new FormData();

    formData.append("receiver", transmittalReceiver.current.value);
    formData.append("transmittals", JSON.stringify(requisitions));
    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("transmittal_file", images[i]);
      }
    }

    const response = await ApiService.forms.submitTransmittalRequest(id, formData);
    const { success, transmittal } = response;
    if (!success) return showSwal(common.errors.SUBMIT_TRANSMITTAL_FORM_ERROR);
    setSubmitting(false);
    btnSubmitText.current.style.display = "block";
    btnLoaderImg.current.style.display = "none";
    setReferenceId(transmittal.reference_id);
    setDateAndTime(convertTo12HourPlusDateFormat(transmittal.createdAt));
    setFormSubmitted(true);
  };

  return (
    <div className='epr-form'>
      <div className='title'>
        <h3>Transmittal Form</h3>
      </div>
      <div className='epr-form-fields'>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>
                Receiver <span className='epr-required'>*</span>
              </label>
            </div>
            <input type='text' ref={transmittalReceiver} placeholder='Juan dela Cruz' />
          </div>
        </div>
        <div className='epr-form-row'>
          <div className='purchase-requisition-dynamic'>
            <div className='purchase-requisition-fields'>
              {requisitions.map((requisition) => (
                <TransmittalItemDetails
                  key={requisition.id}
                  id={requisition.id}
                  onRemove={removeRequisition}
                  onAdd={addRequisition}
                  handleTotalCostChange={handleTotalCostChange}
                  updateRequisition={updateRequisition}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>Attached Image of Receiver Signature</label>
            </div>
            <input type='file' onChange={handleImageChange} />
          </div>
        </div>
      </div>
      <div className='submit-action-btn'>
        <button className='search-btn submit-request' onClick={(e) => handleSubmitRequest()}>
          <span className='form-btn-txt' ref={btnSubmitText}>
            Submit Request
          </span>
          <img src={loader2} className='form-loader' ref={btnLoaderImg} style={{ display: "none" }} />
        </button>
      </div>
    </div>
  );
}
export default EprForm;
