import React, { useEffect, useState } from "react";
import InputField from "./input-field";
import FileContainer from "./file-container";

function PurchaseRequisition({ onRemove, onAdd, id, handleTotalCostChange, updateVisits }) {
  const [currentQuantity, setQuantity] = useState(0);
  const [currentPrice, setPrice] = useState(0);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const [attachmentPreview, setAttachmentPreview] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);

  const [filePreview, setFilePreview] = useState([]);
  const [fileData, setFileData] = useState([]);

  const [description, setDescription] = useState("");
  const [remarks, setRemarks] = useState("");

  const autoCompute = () => {
    const cost = currentPrice * currentQuantity;
    setEstimatedCost(cost);
    setTimeout(() => {
      handleTotalCostChange();
      handleStructurePurchase();
    }, 500);
  };

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeout = setTimeout(() => {
      autoCompute();
    }, 1000);

    setTypingTimeout(timeout);

    return () => clearTimeout(timeout);
  }, [currentPrice, currentQuantity]);

  const handleOnChange = (fieldName, eventTarget) => {
    let value = "";
    if (fieldName === "Description" || fieldName === "Remarks") {
      value = eventTarget.value;
    } else {
      value = parseFloat(eventTarget.value) || 0;
    }
    if (fieldName === "Quantity") {
      setQuantity(value);
    } else if (fieldName === "Estimated Price") {
      setPrice(value);
    } else if (fieldName === "Description") {
      setDescription(value);
    } else if (fieldName === "Remarks") {
      setRemarks(value);
    }
  };

  const handleAttachmentChange = (event, key) => {
    const files = event.target.files;

    const newFilePreviews = [];
    const newSelectedFiles = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const fileSizeMB = files[i].size / 1024 / 1024;

        const objProperty = {
          filePreview: URL.createObjectURL(files[i]),
          file: files[i],
          type: files[i].type,
          size: fileSizeMB,
        };
        newSelectedFiles.push(objProperty);
        newFilePreviews.push(objProperty);
      }
      if (key === "attachment") {
        setAttachmentData(newSelectedFiles);
        setAttachmentPreview(newFilePreviews);
      }
      if (key === "file") {
        setFileData(newSelectedFiles);
        setFilePreview(newFilePreviews);
      }
    }
  };

  const handleAttachmentRemove = (filePreviewUrl, key) => {
    if (key === "attachment") {
      setAttachmentPreview(attachmentPreview.filter((file) => file.filePreview !== filePreviewUrl));
      setAttachmentData(attachmentData.filter((file) => file.filePreview !== filePreviewUrl));
    }

    if (key === "file") {
      setFileData(fileData.filter((file) => file.filePreview !== filePreviewUrl));
      setFilePreview(filePreview.filter((file) => file.filePreview !== filePreviewUrl));
    }
  };

  const handleStructurePurchase = () => {
    const structure = {
      id: id,
      description: description,
      remarks: remarks,
      quantity: currentQuantity,
      price: currentPrice,
      cost: estimatedCost,
      attachment: attachmentData,
      file: fileData,
    };

    updateVisits(structure);
  };

  useEffect(() => {
    handleStructurePurchase();
  }, [description, remarks, currentQuantity, currentPrice, estimatedCost, attachmentData, fileData]);

  return (
    <div className='purchase-requisition-whole-row' id={`pr-${id}`}>
      <div className='purchase--fields'>
        <div className='field-action'>
          <i className='fa fa-plus-circle fa-3x' aria-hidden='true' onClick={(e) => onAdd()}></i>
          <i
            className='fa fa-trash fa-3x'
            aria-hidden='true'
            onClick={(e) => {
              onRemove(id);
              handleTotalCostChange();
            }}
          ></i>
        </div>
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Company Name"} required={true} handleOnChange={handleOnChange} />
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Comments"} required={false} handleOnChange={handleOnChange} />
      </div>
      <div className='purchase--fields'>
        <InputField
          fieldName={"Action To Be Done"}
          required={true}
          handleOnChange={handleOnChange}
          value={currentQuantity}
        />
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Order"} required={false} handleOnChange={handleOnChange} value={currentPrice} />
      </div>
      <div className='purchase--fields'>
        <div className='fields'>
          <span className='field-name'>Proof of Visit</span>
          <div className='files-attachment-container'>
            <div className='files-attachment-input'>
              <input type='file' onChange={(e) => handleAttachmentChange(e, "attachment")} multiple />
            </div>
            <div className='files-upload-container'>
              {attachmentPreview.map((attachment, index) => (
                <FileContainer
                  attachment={attachment}
                  key={index}
                  handleAttachmentRemove={handleAttachmentRemove}
                  removeKey={"attachment"}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseRequisition;
