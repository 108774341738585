import React, { useState, useContext, useEffect, useRef } from "react";
import "../../styles/offsetting.css";
import loader2 from "../../images/loader_2.gif";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import { convertTo12HourPlusDateFormat } from "../../util/helperFunctions";
import "../../styles/explain-why-form.css";

function OffsettingForm({ formDetails, setReferenceId, setDateAndTime, setFormSubmitted }) {
  const loaderRef = useRef(null);

  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");

  const [images, setImages] = useState(null);

  const [formData, setFormData] = useState({
    specificAddress: "",
    companyName: "",
    contactName: "",
    position: "",
    mobileNumber: null,
    telephone1: null,
    telephone2: null,
    supplier: null,
    volume: null,
    prospectsEmail: null,
  });

  const handleImageChange = (event) => {
    const files = event.target.files;
    setImages(files);
  };

  const handleDataChange = (key, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedProvince("");
  };

  const penalties = [
    "Verbal Warning",
    "Written Warning",
    "Final Written Warning",
    "3 Days Suspension",
    "5 Days Suspension",
    "7 Days Suspension",
    "10 Days Suspension",
    "12 Days Suspension",
    "15 Days Suspension",
    "Dismissal or Termination",
  ];

  const rules = [
    "I. Acts of Dishonesty/Disloyalty",
    "II. Conduct and Behavior",
    "III. Job Performance",
    "IV. Attendance",
    "V. Safety & Security, Sanitation & Health",
    "VI. Company Property & Assets",
  ];

  const offense = ["1st Offense", "2nd Offense", "3rd Offense", "4th Offense", "5th Offense", "Other"];
  const handleSubmit = async (e) => {
    const {
      specificAddress,
      companyName,
      position,
      mobileNumber,
      telephone1,
      telephone2,
      supplier,
      volume,
      prospectsEmail,
    } = formData;

    const _formData = new FormData();
    _formData.append("specificAddress", specificAddress);
    _formData.append("companyName", companyName);
    _formData.append("position", position);
    _formData.append("mobileNumber", mobileNumber);
    _formData.append("telephone1", telephone1);
    _formData.append("telephone2", telephone2);
    _formData.append("supplier", supplier);
    _formData.append("volume", volume);
    _formData.append("prospectsEmail", prospectsEmail);

    if (images) {
      for (let i = 0; i < images.length; i++) {
        _formData.append("prospectingImages", images[i]);
      }
    }

    e.target.style.display = "none";
    loaderRef.current.style.display = "inline-block";

    const response = await ApiService.forms.submitProspectingRequest(formDetails.id, _formData);
    const { success, prospecting } = response;
    if (!success) return showSwal(common.errors.SUBMIT_OFFSET_FORM_ERROR);
    setSubmitting(false);
    e.target.style.display = "block";
    loaderRef.current.style.display = "none";
    setReferenceId(prospecting.reference_id);
    setDateAndTime(convertTo12HourPlusDateFormat(prospecting.createdAt));
    setFormSubmitted(true);
  };

  return (
    <div className='epr-form-container'>
      <div className='epr-title'>
        <h3>Explain Why You Should Not Be Penalized Memo</h3>
      </div>
      <div className='epr-form-group'>
        <div className='epr-form-fields-prospecting'>
          <label htmlFor=''>
            Manager<span className='epr-required'>*</span>
          </label>
          <input type='text' onChange={(e) => handleDataChange("supplier", e.target.value)} />
        </div>
      </div>
      <div className='epr-title'>
        <h3>Infractions and Penalties</h3>
      </div>
      <div className='epr-form-body'>
        <div className='epr-form-groupings'>
          <div className='epr-form-group'>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor=''>Explain why you will not be penalized for</label>
              <textarea
                onChange={(e) => handleDataChange("specificAddress", e.target.value)}
                placeholder='Manager to type the reason why he or she will be penalized.'
              />
            </div>

            <div className='epr-form-fields-prospecting'>
              <label htmlFor='region'>Penalties</label>
              <select name='region' id='region' value={selectedRegion} onChange={handleRegionChange}>
                <option value=''>Select Penalty</option>
                {penalties.map((penalty, index) => (
                  <option value={penalty} key={`${index}-${penalty}`}>
                    {penalty}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='epr-form-group'>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor='region'>Offense</label>
              <select name='region' id='region' value={selectedRegion} onChange={handleRegionChange}>
                <option value=''>Select Offense</option>
                {offense.map((offense, index) => (
                  <option value={offense} key={`${index}-${offense}`}>
                    {offense}
                  </option>
                ))}
              </select>
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor='region'>
                Rule No.<span className='epr-required'>*</span>
              </label>
              <select name='region' id='region' value={selectedRegion} onChange={handleRegionChange}>
                <option value=''>Select Rule Number</option>
                {rules.map((rule, index) => (
                  <option value={rule} key={`${index}-${rule}`}>
                    {rule}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='epr-form-group'>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor='images'>File Upload</label>
              <input type='file' id='images' multiple onChange={handleImageChange} />
            </div>
            <div className='epr-form-fields-prospecting'>
              <label htmlFor='images'>Signature of Manager</label>
              <input type='file' id='images' multiple onChange={handleImageChange} />
            </div>
          </div>
        </div>
        <div className='epr-submit-action-btn'>
          <button className='epr-submit-request' onClick={(e) => handleSubmit(e)}>
            <span className='epr-form-btn-txt'>Submit Request</span>
            <img src={loader2} className='epr-form-loader' style={{ display: "none" }} ref={loaderRef} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default OffsettingForm;
