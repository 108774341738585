import React, { useEffect, useState } from "react";
import InputField from "./input-field";
import FileContainer from "./file-container";

function PurchaseRequisition({ onRemove, onAdd, id, handleTotalCostChange, updateRequisition }) {
  const [currentQuantity, setQuantity] = useState(0);
  const [currentPrice, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [remarks, setRemarks] = useState("");

  const handleOnChange = (fieldName, eventTarget) => {
    let value = "";
    if (fieldName === "Received Items" || fieldName === "Remarks") {
      value = eventTarget.value;
    } else {
      value = parseFloat(eventTarget.value) || 0;
    }
    if (fieldName === "Quantity") {
      setQuantity(value);
    } else if (fieldName === "Amount") {
      setPrice(value);
    } else if (fieldName === "Received Items") {
      setDescription(value);
    } else if (fieldName === "Remarks") {
      setRemarks(value);
    }
  };

  const handleStructurePurchase = () => {
    const structure = {
      id: id,
      description: description,
      remarks: remarks,
      quantity: currentQuantity,
      price: currentPrice,
    };

    updateRequisition(structure);
  };

  useEffect(() => {
    handleStructurePurchase();
  }, [description, remarks, currentQuantity, currentPrice]);

  return (
    <div className='purchase-requisition-whole-row' id={`pr-${id}`}>
      <div className='purchase--fields'>
        <div className='field-action'>
          <i className='fa fa-plus-circle fa-3x' aria-hidden='true' onClick={(e) => onAdd()}></i>
          <i
            className='fa fa-trash fa-3x'
            aria-hidden='true'
            onClick={(e) => {
              onRemove(id);
              handleTotalCostChange();
            }}
          ></i>
        </div>
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Received Items"} required={true} handleOnChange={handleOnChange} />
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Quantity"} required={true} handleOnChange={handleOnChange} value={currentQuantity} />
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Amount"} required={false} handleOnChange={handleOnChange} value={currentPrice} />
      </div>
      <div className='purchase--fields'>
        <InputField fieldName={"Remarks"} required={false} handleOnChange={handleOnChange} />
      </div>
    </div>
  );
}

export default PurchaseRequisition;
